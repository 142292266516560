"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReactMultiEmail = void 0;
const React = __importStar(require("react"));
const isEmail_1 = require("./isEmail");
function ReactMultiEmail(props) {
    const { id, style, className = '', noClass, placeholder, autoFocus, allowDisplayName = false, stripDisplayName = false, allowDuplicate = false, delimiter = `[${allowDisplayName ? '' : ' '},;]`, initialInputValue = '', inputClassName, autoComplete, getLabel, enable, onDisabled, validateEmail, onChange, onChangeInput, onFocus, onBlur, onKeyDown, onKeyUp, spinner, disableOnBlurValidation = false, inputValue, } = props;
    const emailInputRef = React.useRef(null);
    const [focused, setFocused] = React.useState(false);
    const [emails, setEmails] = React.useState([]);
    const [inpValue, setInpValue] = React.useState('');
    const [spinning, setSpinning] = React.useState(false);
    const findEmailAddress = React.useCallback((value, isEnter) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        const validEmails = [];
        let _inputValue = '';
        const re = new RegExp(delimiter, 'g');
        const isEmail = validateEmail || isEmail_1.isEmail;
        const addEmails = (email) => {
            if (!allowDuplicate) {
                for (let i = 0, l = emails.length; i < l; i++) {
                    if (emails[i].toLowerCase() === email.toLowerCase()) {
                        return false;
                    }
                }
            }
            validEmails.push(email);
            return true;
        };
        if (value !== '') {
            if (re.test(value)) {
                const setArr = new Set(value.split(re).filter(n => n));
                const arr = [...setArr];
                while (arr.length) {
                    const validateResult = isEmail('' + arr[0].trim());
                    if (typeof validateResult === 'boolean') {
                        if (validateResult) {
                            addEmails('' + ((_a = arr.shift()) === null || _a === void 0 ? void 0 : _a.trim()));
                        }
                        else {
                            if (allowDisplayName) {
                                const validateResultWithDisplayName = isEmail('' + arr[0].trim(), { allowDisplayName });
                                if (validateResultWithDisplayName) {
                                    // Strip display name from email formatted as such "First Last <first.last@domain.com>"
                                    const email = stripDisplayName ? (_b = arr.shift()) === null || _b === void 0 ? void 0 : _b.split('<')[1].split('>')[0] : arr.shift();
                                    addEmails('' + email);
                                }
                                else {
                                    if (arr.length === 1) {
                                        _inputValue = '' + arr.shift();
                                    }
                                    else {
                                        arr.shift();
                                    }
                                }
                            }
                            else {
                                _inputValue = '' + arr.shift();
                            }
                        }
                    }
                    else {
                        // handle promise
                        setSpinning(true);
                        if ((yield (validateEmail === null || validateEmail === void 0 ? void 0 : validateEmail(value))) === true) {
                            addEmails('' + arr.shift());
                            setSpinning(false);
                        }
                        else {
                            if (arr.length === 1) {
                                _inputValue = '' + arr.shift();
                            }
                            else {
                                arr.shift();
                            }
                        }
                    }
                }
            }
            else {
                if (enable && !enable({ emailCnt: emails.length })) {
                    onDisabled === null || onDisabled === void 0 ? void 0 : onDisabled();
                    return;
                }
                if (isEnter) {
                    const validateResult = isEmail(value);
                    if (typeof validateResult === 'boolean') {
                        if (validateResult) {
                            addEmails(value);
                        }
                        else if (allowDisplayName) {
                            const validateResultWithDisplayName = isEmail(value, { allowDisplayName });
                            if (validateResultWithDisplayName) {
                                // Strip display name from email formatted as such "First Last <first.last@domain.com>"
                                const email = stripDisplayName ? value.split('<')[1].split('>')[0] : value;
                                addEmails(email);
                            }
                            else {
                                _inputValue = value;
                            }
                        }
                        else {
                            _inputValue = value;
                        }
                    }
                    else {
                        // handle promise
                        setSpinning(true);
                        if ((yield (validateEmail === null || validateEmail === void 0 ? void 0 : validateEmail(value))) === true) {
                            addEmails(value);
                        }
                        else {
                            _inputValue = value;
                        }
                        setSpinning(false);
                    }
                }
                else {
                    _inputValue = value;
                }
            }
        }
        setEmails([...emails, ...validEmails]);
        setInpValue(_inputValue);
        if (validEmails.length) {
            onChange === null || onChange === void 0 ? void 0 : onChange([...emails, ...validEmails]);
        }
        if (inputValue !== _inputValue) {
            onChangeInput === null || onChangeInput === void 0 ? void 0 : onChangeInput(_inputValue);
        }
    }), [
        allowDisplayName,
        allowDuplicate,
        delimiter,
        emails,
        enable,
        inputValue,
        onChange,
        onChangeInput,
        onDisabled,
        stripDisplayName,
        validateEmail,
    ]);
    const onChangeInputValue = React.useCallback((value) => __awaiter(this, void 0, void 0, function* () {
        yield findEmailAddress(value);
    }), [findEmailAddress]);
    const removeEmail = React.useCallback((index, isDisabled) => {
        if (isDisabled) {
            return;
        }
        const _emails = [...emails.slice(0, index), ...emails.slice(index + 1)];
        setEmails(_emails);
        onChange === null || onChange === void 0 ? void 0 : onChange(_emails);
    }, [emails, onChange]);
    const handleOnKeydown = React.useCallback((e) => {
        onKeyDown === null || onKeyDown === void 0 ? void 0 : onKeyDown(e);
        switch (e.key) {
            case 'Enter':
                e.preventDefault();
                break;
            case 'Backspace':
                if (!e.currentTarget.value) {
                    removeEmail(emails.length - 1, false);
                }
                break;
            default:
        }
    }, [emails.length, onKeyDown, removeEmail]);
    const handleOnKeyup = React.useCallback((e) => __awaiter(this, void 0, void 0, function* () {
        onKeyUp === null || onKeyUp === void 0 ? void 0 : onKeyUp(e);
        switch (e.key) {
            case 'Enter':
                yield findEmailAddress(e.currentTarget.value, true);
                break;
            default:
        }
    }), [findEmailAddress, onKeyUp]);
    const handleOnChange = React.useCallback((e) => __awaiter(this, void 0, void 0, function* () { return yield onChangeInputValue(e.currentTarget.value); }), [onChangeInputValue]);
    const handleOnBlur = React.useCallback((e) => __awaiter(this, void 0, void 0, function* () {
        setFocused(false);
        if (!disableOnBlurValidation) {
            yield findEmailAddress(e.currentTarget.value, true);
        }
        onBlur === null || onBlur === void 0 ? void 0 : onBlur();
    }), [disableOnBlurValidation, findEmailAddress, onBlur]);
    const handleOnFocus = React.useCallback(() => {
        setFocused(true);
        onFocus === null || onFocus === void 0 ? void 0 : onFocus();
    }, [onFocus]);
    React.useEffect(() => {
        setInpValue(initialInputValue);
    }, [initialInputValue]);
    React.useEffect(() => {
        setInpValue(inputValue !== null && inputValue !== void 0 ? inputValue : '');
    }, [inputValue]);
    React.useEffect(() => {
        var _a;
        if (validateEmail) {
            (() => __awaiter(this, void 0, void 0, function* () {
                var _b, e_1, _c, _d;
                var _e;
                setSpinning(true);
                const validEmails = [];
                try {
                    for (var _f = true, _g = __asyncValues((_e = props.emails) !== null && _e !== void 0 ? _e : []), _h; _h = yield _g.next(), _b = _h.done, !_b;) {
                        _d = _h.value;
                        _f = false;
                        try {
                            const email = _d;
                            if (yield validateEmail(email)) {
                                validEmails.push(email);
                            }
                        }
                        finally {
                            _f = true;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (!_f && !_b && (_c = _g.return)) yield _c.call(_g);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                setEmails(validEmails);
                setSpinning(false);
            }))();
        }
        else {
            const validEmails = (_a = props.emails) === null || _a === void 0 ? void 0 : _a.filter(email => {
                return (0, isEmail_1.isEmail)(email);
            });
            setEmails(validEmails !== null && validEmails !== void 0 ? validEmails : []);
        }
    }, [props.emails, validateEmail]);
    return (React.createElement("div", { className: `${className} ${noClass ? '' : 'react-multi-email'} ${focused ? 'focused' : ''} ${inpValue === '' && emails.length === 0 ? 'empty' : 'fill'}`, style: style, onClick: () => { var _a; return (_a = emailInputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); } },
        spinning && (spinner === null || spinner === void 0 ? void 0 : spinner()),
        placeholder ? React.createElement("span", { "data-placeholder": true }, placeholder) : null,
        React.createElement("div", { className: 'data-labels', style: { opacity: spinning ? 0.45 : 1.0, display: 'contents', flexWrap: 'inherit' } }, emails.map((email, index) => getLabel(email, index, removeEmail))),
        React.createElement("input", { id: id, style: { opacity: spinning ? 0.45 : 1.0 }, ref: emailInputRef, type: 'text', value: inpValue, onFocus: handleOnFocus, onBlur: handleOnBlur, onChange: handleOnChange, onKeyDown: handleOnKeydown, onKeyUp: handleOnKeyup, autoFocus: autoFocus, className: inputClassName, autoComplete: autoComplete })));
}
exports.ReactMultiEmail = ReactMultiEmail;
